<template>
  <div class="main">
    <div class="loading">
      <van-loading size="50" />
    </div>
  </div>
</template>

<script>
import GoogleMapApi from '@/api/google-map-api'
export default {
  mounted() {
    GoogleMapApi.clientSideKey().then(res=>{
      let script = document.createElement('script')
      script.id='googleMap'
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+res.data.clientSideKey+'&libraries=places&sensor=true&libraries=drawing,places,geometry,spherical'
      let app = document.getElementById('app')
      parent = app.parentNode;
      if (parent.lastChild == app) {
          parent.appendChild(script);
      } else {
          parent.insertBefore(script, app.nextSibling);
      }
      let url = localStorage.getItem('url')
      localStorage.removeItem('url')
      setTimeout(()=>{
        this.$router.replace({ path:url})
      },500)
      // this.$route
    })
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  .loading {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
